<template>
  <ValidationObserver ref="observer">
    <b-row class="text-left">
      <b-col cols="12">
        <h3 class="mb-2">
          {{ textRekomendasi.selectmode }}
        </h3>
        <b-list-group flush>
          <validation-provider
            #default="{ errors }"
            name="Rekomendasi"
            rules="required"
          >
            <b-list-group-item
              v-for="(data, index) in textRekomendasi.daftar"
              :key="index"
              class="d-flex justify-content-between"
            >
              <b-form-checkbox
                :ref="`reko-${textRekomendasi.mode}-${data.rekomendasi_id}`"
                v-model="textRekomendasi.select"
                :value="data.rekomendasi_id"
                plain
              >
                {{ data.rekomendasi }}
              </b-form-checkbox>
              <b-button
                variant="flat-secondary"
                class=""
                size="sm"
                @click="copyReko(data.rekomendasi)"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="10"
                />
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-form-group
                label="Rekomendasi Tambahan"
                label-for="reko_description"
              >
                <b-form-textarea
                  id="vi-reko_description"
                  v-model="textRekomendasi.note"
                  rows="5"
                  name="reko_description"
                  placeholder="Rekomendasi Tambahan"
                />
              </b-form-group>
            </b-list-group-item>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-list-group>
      </b-col>
      <b-col
        v-if="errorStat"
        cols="12"
      >
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            Error Found: {{ errorMsg }}
          </div>
        </b-alert>
      </b-col>
      <b-col class="py-1">
        <b-button
          variant="success"
          size="sm"
          @click="updateSave"
        >
          Simpan Rekomendasi Tambahan
        </b-button>
        <b-button
          v-if="dataSaved"
          variant="flat-success"
          size="sm"
          class="mt-0 ml-50"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="14"
          /> Rekomendasi Berhasil Disimpan
        </b-button>
      </b-col>
    </b-row>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAlert, BButton, BListGroup, BListGroupItem, BRow, BCol, BFormCheckbox, BFormTextarea, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BButton,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    textRekomendasi: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      dataSaved: false,
      errorStat: false,
      errorMsg: '',
    }
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    updateSave() {
      const verif = {
        domain: this.textRekomendasi.program.domain,
        data_id: this.textRekomendasi.program.data_id,
        tipe_rekomendasi: (this.textRekomendasi.mode === 'yes') ? 1 : -1,
        rekomendasi_id: this.textRekomendasi.select,
        data_utama_id: this.textRekomendasi.program.data_utama_id,
        rekomendasi_tambahan: this.textRekomendasi.note,
      }
      if (this.textRekomendasi.program.domain === 'APLIKASI') {
        verif.aplikasi_id = this.textRekomendasi.program.data_id
      } else {
        verif.infrastruktur_id = this.textRekomendasi.program.data_id
      }
      if (verif.rekomendasi_tambahan === '' || verif.rekomendasi_tambahan === null) {
        this.$delete(verif, 'rekomendasi_tambahan')
      }
      this.$refs.observer.validate()
        .then(result => {
          if (result === true) {
            this.sendRekomendasi(verif)
          }
        })
    },
    sendRekomendasi(verif) {
      this.$http.post('/dirjen/submit', verif, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data) {
            this.dataSaved = true
            this.rekoDesc = ''
            this.errorStat = false
            this.errorMsg = ''

            setTimeout(() => {
              this.$emit('result', res.data.data)
              this.dataSaved = false
            }, 1000)
          }
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>
