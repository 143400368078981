<template>
  <div v-if="show">
    <h6>{{ statusRekomendasi }}</h6>
    <b-list-group
      class="mb-1"
      flush
    >
      <b-list-group-item
        v-for="(data, index) in listRekomendasi"
        :key="index"
        class="px-0 py-50"
      >
        {{ data }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    rekapDetail: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      statusRekomendasi: '',
      listRekomendasi: [],
    }
  },
  watch: {
    rekapDetail() {
      this.getRekomendasi()
    },
  },
  mounted() {
    this.getRekomendasi()
  },
  methods: {
    getRekomendasi() {
      if (this.rekapDetail) {
        this.rekapDetail.map(values => {
          if (values.is_continue === 1) {
            this.statusRekomendasi = 'Dilanjutkan'
          } else {
            this.statusRekomendasi = 'Tidak Dilanjutkan'
          }
          this.listRekomendasi.push(values.recommendation)
          return true
        })
        this.show = true
      }
    },
  },
}
</script>
